import React, { Component } from 'react'
import { Checkbox, Image, Button, Form, Grid, Header, Message, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import 'whatwg-fetch'
import banner from './header.png'
import event from './cover.png'
import titolo from './titolo.png'
import ita from './ita.png'
import eng from './eng.png'
export default class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      error: null,
      name: "",
      surname: "",
      email: "",
      company: "",
      terms: false,
      redirect: (localStorage.getItem("jwtFinal2023") !== null)
    }
  }
  
  login () {
    this.setState({loading: true})

    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var bodyObject = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      company: this.state.company
    }

    if (!this.state.terms) {
      this.setState({error: "Devi accettare i termini e le condizioni per continuare", loading: false});
      return;
    }

    fetch('https://siad2024-api.reportcongressi.com/register', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(bodyObject)
    }).then(function(res) {
      if (res.ok) {
        res.json().then(function (data) {
          localStorage.setItem("jwtFinal2023", data.jwtToken)
          this.setState({redirect: true, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      } else {
        res.json().then(function (data) {
          this.setState({error: data.reason, loading: false})
        }.bind(this)).catch(function(err) {
          console.log(err)
        })
      }
    }.bind(this)).catch(function(err) {
      this.setState({loading: false, error: "Errore inaspettato, ricarica la pagina per riprovare."})
    }.bind(this))
    /*setTimeout(function () {
      this.setState({
        loading: false,
        error: true
      })
    }.bind(this), 3000)*/
  }

  render () {
    return (
      <div className='login-form'>
      {this.state.redirect && (
        <Redirect to="/language" />
      )}
      {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
      <Grid stretched style={{ marginBottom: "1vw" }}>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <img src={banner} style={{maxWidth: "100%", backgroundColor: "white"}} />
        </Grid.Column>
      </Grid>
      <Grid centered stretched style={{ marginBottom: "1vw" }}>
        <Grid.Column computer={8} tablet={8} mobile={12}>
          <img src={titolo} style={{maxWidth: "100%"}} />
        </Grid.Column>
      </Grid>
      <Grid
        verticalAlign='middle'
      >
        <Grid.Column computer={10} tablet={16} mobile={16}>
          <img src={event} style={{ maxWidth: "100%", paddingLeft: 40 }} />
        </Grid.Column>
        <Grid.Column computer={5} tablet={16} mobile={16} style={{ maxWidth: 800, padding: "0 30px" }}>
          <Form size='large' onSubmit={this.login.bind(this)}>
              {this.state.loading && (
                <Dimmer active>
                  <Loader />
                </Dimmer>
              )}
              {this.state.error && (
                <Message negative>
                  <p>{this.state.error}</p>
                </Message>
              )}
              <Grid columns={1}>
              <Grid.Column>
                <Form.Input
                  fluid
                  placeholder="Nome/Name"
                  icon="id badge"
                  iconPosition="left"
                  value={this.state.name}
                  onChange={e => this.setState({name: e.target.value})}
                />
              </Grid.Column>
              <Grid.Column>
              <Form.Input
                  fluid
                  placeholder="Cognome/Surname"
                  icon="id badge"
                  iconPosition="left"
                  value={this.state.surname}
                  onChange={e => this.setState({surname: e.target.value})}
                />
              </Grid.Column>
              </Grid>
              <Grid columns={1} style={{ marginBottom: 10 }}>
                <Grid.Column>
                <Form.Input
                fluid
                icon="address book outline"
                iconPosition="left"
                placeholder="Azienda Filiale/Company Branch"
                value={this.state.company}
                onChange={e => this.setState({company: e.target.value})}
              />
                </Grid.Column>
              </Grid>
              <Form.Field
                control={Checkbox}
                style={{ textAlign: "left" }}
                onChange={(e, { checked }) => this.setState({terms: checked})}
                label={{ children: <b><a style={{color: "#00B13F"}} target="_blank" href="https://www.siad.com/documents/30774/639797/Modello+Info_utenti+Sito+WEB-uk.pdf/cd6fe3b5-0027-876b-2956-1d2a854dba5b">Privacy Policy</a></b> }}
              />
              <Button className="register-button" color='green' size='large' style={{width: 'auto', display: 'inline-block' }}>START</Button>
          </Form>
        </Grid.Column>
      </Grid>
      <Grid
        textAlign='center'
        verticalAlign='middle'
      >
        <Grid.Column style={{ backgroundColor: "#00AE41", marginTop: 80, fontSize: 16, textAlign: "center", color: "white", padding: "20px 30px" }}>
          <p>
            Il player video utilizzato per la trasmissione è Vimeo e si consigliano browser come Chrome, Firefox, Edge o Safari.
            Se nella pagina seguente vedete il video e sentite l'audio, significa che siete collegati correttamente e dovete solo attendere che l'evento inizi all'ora stabilita.
            Se vi siete loggati con anticipo rispetto all'inizio, potrebbe essere necessario un refresh della pagina.
          </p>
          <p>
            The video player used for the broadcast will be Vimeo and Chrome, Firefox, Edge or Safari are the suggested browsers to open it.
            If you can see the video and hear the sound on the following page, it means you are correctly connected. You just have to wait for the event to start.
            If you logged in earlier that the start time, it may necessary to refresh the page.
          </p>
        </Grid.Column>
      </Grid>
    </div>
    )
  }
}